import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AppBasicsModule, AppBasicsModuleConfigModel } from '@meddev/fe-shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { IntercomModule } from 'ng-intercom';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActiveModuleGuard } from './_common/guards/active-module.guard';
import { AuthGuard } from './_common/guards/auth.guard';
import { TokenInterceptorService } from './_common/guards/token.interceptor.service';
// import { OimModule } from './_modules/oim/oim.module';
import { EnvironmentHelper } from './_common/helper/environment-helper';
import { MedaViewModule } from './_common/modules/meda-view/meda-view.module';
import { BaseState } from './_common/state/base/base.state';

EnvironmentHelper.initEnvironment();

// import * as PDFJS from 'pdfjs-dist';
// PDFJS.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        OverlayModule,
        NgSelectModule,
        NgxsModule.forRoot([BaseState], {
            developmentMode: !environment?.production,
        }),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({ disabled: environment?.production }),
        BrowserAnimationsModule,
        // PipesModule,
        MedaViewModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        IntercomModule.forRoot({
            appId: environment.INTERCOM_KEY,
            updateOnRouterChange: true,
        }),
        // OimModule,
        NgxPermissionsModule.forRoot(),
        FullCalendarModule,
        AppBasicsModule.forRoot({
            version: environment.VERSION,
            production: environment.production,
            releaseStage: environment.RELEASE_STAGE as AppBasicsModuleConfigModel['releaseStage'],
            bugSnag: null,
            matomo: {
                siteId: '3',
                trackerUrl: 'https://data.medifit.si/',
            },
        }),
    ],
    providers: [
        AuthGuard,
        ActiveModuleGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
